var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isRentedInManagement && _vm.info.totalPropertySummary)?_c('div',{staticClass:"pt-16 pb-12 px-16 mb-32 border-sm border-gray30 rounded-md flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-col"},[_c('p',{staticClass:"text-black text-size4 font-medium"},[_vm._v(" "+_vm._s(_vm.$t("common.accounting"))+" ")]),_c('p',{staticClass:"text-gray70 text-size3"},[_vm._v(" "+_vm._s(_vm.$t("common.yearBalance", { year: _vm.currentYear }))+" ")]),_c('div',{staticClass:"flex flex-row items-center gap-8 mt-16"},[_c('HousfySvg',{attrs:{"svg":"icons/euro","width":"16","height":"16"}}),_c('p',{staticClass:"text-black text-size6 font-medium"},[_vm._v(" "+_vm._s(_vm.annualIncome)+" ")])],1)]),_c('div',{staticClass:"self-end"},[_c('HousfySvg',{attrs:{"svg":"icons/arrow-right","width":"16","height":"16","svg-class":"arrow"}})],1)]):_vm._e(),_c('div',{staticClass:"flex flex-col text-gray70 text-size4 gap-8"},[(_vm.info.rentedDate)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('common.effectiveFromX', { fromDate: _vm.fromDate }))}}):_vm._e(),(_vm.info.monthlyPrice)?_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t('common.monthlyRentX', {
          monthlyPrice: _vm.monthlyPrice,
        })
      )}}):_vm._e(),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('common.rentCollection'))}})]),(_vm.isRentedInManagement && _vm.info.numberOfTimesRented)?_c('div',{staticClass:"flex flex-col text-gray70 text-size4 mt-32 gap-4"},[_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t('common.rentedColonXTimes', {
          x: _vm.info.numberOfTimesRented,
          times: _vm.$tc('common.time', _vm.info.numberOfTimesRented),
        })
      )}}),_c('HousfyLink',{attrs:{"to":_vm.historyLink,"tracking":"link_history_viewHistory","data-qa":"historyLink-viewHistory"}},[_c('HousfyAnchor',{staticClass:"link",attrs:{"variant":"primary","size":"lg","prevent-default":false}},[_vm._v(_vm._s(_vm.$t("common.viewHistory")))])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }