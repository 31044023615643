
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPropertyInfo } from "@/models/PropertyInfo";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { OwnerLeadStatusCategory } from "@/enums/StatusCategory";
import DateUtils from "@/services/utils/DateUtils";
import Money from "@/models/Money";
import { HousfyAnchor } from "housfy-ui-lib";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import { IRoute } from "@/models/IRoute";

@Component({
  name: "PropertySpecs",
  components: {
    HousfySvg,
    HousfyAnchor,
    HousfyLink,
  },
})
export default class PropertySpecs extends Vue {
  @Prop({ type: Object, default: () => {} })
  info!: IPropertyInfo;

  propertyUuid = this.$route.params.uuid;

  get isRentedInManagement() {
    return (
      this.info.statusCategory === OwnerLeadStatusCategory.RENTED_IN_MANAGEMENT
    );
  }

  get currentYear(): string {
    return new Date().getFullYear().toString();
  }

  get fromDate() {
    const { day, month, year } = DateUtils.formatDate(this.info.rentedDate);
    return day + " " + this.$t("common." + month + "Short") + " " + year;
  }

  get annualIncome(): string {
    return new Money(Number(this.info.totalPropertySummary.amount) / 100)
      .withOptions({
        withCents: true,
        currency: this.info.totalPropertySummary.currency,
      })
      .format(true);
  }

  get monthlyPrice(): string {
    return new Money(Number(this.info.monthlyPrice.amount) / 100)
      .withOptions({
        withCents: true,
        currency: this.info.monthlyPrice.currency,
      })
      .format(true);
  }

  get historyLink(): IRoute {
    return {
      name: "history",
      params: {
        uuid: this.propertyUuid,
      },
    };
  }
}
